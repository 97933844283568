import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
import { useSelector } from 'react-redux';
import Text from '../Text';

const LogoContainer = styled.div`
  //max-width: ${props => (props.theme.logo_size ? props.theme.logo_size + 'px' : 'auto')};
  margin-right: 30px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 'auto')};
  a {
    outline: none;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  ${respondTo.sm`
       margin-right: 60px;
  `};
`;

const Logo = props => {
  const { nolink, logo } = props;
  const homeData = useSelector(state => state.cms.home);
  const logoCms = homeData[0].contents.find(el => el.name === 'logo');

  let logoContent = null;

  if (nolink) {
    logoContent = <img src={logo} alt='' />;
  } else {
    logoContent = (
      <Link to='/'>
        <img src={logo} alt='' />
        {logoCms?.i18l?.content && <Text>{logoCms?.i18l?.content}</Text>}
      </Link>
    );
  }

  return <LogoContainer {...props}>{logoContent}</LogoContainer>;
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
