import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Notification from './Notification';
import { respondTo } from '../../theme/mixin';
import SliderProductContinue from '../sliderProductContinue';
import Fade from '../Fade';
import { getUserInfo } from '../../store/actions';

const Container = styled.div`
  ${respondTo.sm`
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
  `}
`;

function HomeBlocks({ setIsReviews, setpuntiAbbastanza, ios }) {
  const homeData = useSelector(state => state.cms.home);
  const isLogged = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  let arr = homeData?.[0]?.contents?.filter(item => {
    return (
      item.name !== 'logo' &&
      (item.visibility === 'all' ||
        (item.visibility === 'logged' && isLogged) ||
        (item.visibility === 'not_logged' && !isLogged))
    );
  });

  return (
    <Fade>
      <Container>
        {arr?.map((item, i, arr) => {
          const content =
            item.name !== 'title_Home' ? (
              <>
                {item.type === 'catalog' ? (
                  <SliderProductContinue
                    setIsReviews={setIsReviews}
                    data={homeData[0].contents.length > 0 ? homeData[0].contents : []}
                    index={i}
                    list={item.products}
                    title={item.i18l.title}
                    width={item.width}
                    notif={item}
                    setpuntiAbbastanza={setpuntiAbbastanza}
                  />
                ) : item.type === 'promotion' ? (
                  <Notification
                    key={item.id}
                    data={arr}
                    notif={item}
                    bg={item.img}
                    promo
                    width={item.width}
                  />
                ) : (
                  <Notification
                    key={item.id}
                    data={arr}
                    notif={item}
                    bg={item.img}
                    width={item.width}
                  />
                )}
              </>
            ) : null;

          if (item.visibility === 'all') {
            return content;
          }

          if (item.visibility === 'logged' && isLogged) {
            return content;
          }

          if (item.visibility === 'not_logged' && !isLogged) {
            return content;
          }

          return null;
        })}
      </Container>
    </Fade>
  );
}

export default HomeBlocks;
